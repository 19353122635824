﻿import "../Styles/index.scss";
import { ToastType, toast } from "bulma-toast";

let toastMessageElement = document.getElementById("toastData");
if (toastMessageElement != null) {
	toast({
		type: toastMessageElement.dataset["type"] as ToastType,
		position: "top-center",
		duration: 9999,
		dismissible: true,
		message: toastMessageElement.innerText,
	});
}
